import { Component, OnInit } from '@angular/core';
import { Auth2Service } from '../../../services/auth/auth2.service';
import { Router } from '@angular/router';
import { ResponseBody } from '../../../services/models/Api';

@Component({
  selector: 'app-protect',
  templateUrl: './protect.component.html',
  styleUrls: [ './protect.component.less' ]
})
export class ProtectComponent implements OnInit {

  constructor( private router: Router,
               private auth2: Auth2Service ) {
  }

  ngOnInit() {
    // 这里查询用户登录信息,成功之后进行路由跳转
    this.auth2.getUserInfo().subscribe(( res: ResponseBody ) => {
      if ( res.Result === 1 && res.Object ) {
        this.auth2.isOATH = true;
        this.auth2.userInfo = res.Object;
        this.getUserInfoSuccess();
      }
    }, () => {
      this.getUserInfoFiled();
    });
  }

  private getUserInfoSuccess() {
    this.auth2.tempRedirectUrl ? this.router.navigate([ this.auth2.tempRedirectUrl ]) : this.router.navigate([ '/index/home' ]);
    // this.router.navigate([ '/index/home' ]);
  }

  private getUserInfoFiled() {
    this.auth2.isLogIn = false;
    this.auth2.isOATH = false;
    window.location.reload();
  }
}
