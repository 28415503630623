import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap, timeout } from 'rxjs/operators';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { UNWANTED_ERROR_CODE } from '../../utils/constants';
import { Auth2Service } from '../../services/auth/auth2.service';
import { Router } from '@angular/router';
import { env } from '../../config/Config';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor( private translate: TranslateService,
               private notify: NzNotificationService,
               private modalService: NzModalService,
               private auth2: Auth2Service,
               private router: Router, ) {
  }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    if ( req.url.split('?')[ 1 ] ) {
      let newReq: HttpRequest<any>;
      // 特殊处理
      switch ( req.url.split('?')[ 1 ] ) {
        // crm的接口需要将报错信息通过返回参数的ErrorMsg处理
        case 'crm':
          newReq = req.clone({
            url: `${req.url.split('?')[ 0 ]}`,
            headers: req.headers.set('Authorization', `bearer ${this.auth2.userInfo && this.auth2.userInfo.Token ? this.auth2.userInfo.Token : ''}`),
          });
          return next.handle(newReq)
            .pipe(
              // 超时时间（ms）, 这个必须被放在retry前面
              timeout(30000),
              catchError(this.handleError),
              tap(this.handleBusinessErrorCRM),
            );
        // 导出文件返回需要设定返回类型为text格式
        case 'export_file':
          newReq = req.clone({
            url: `${req.url.split('?')[ 0 ]}`,
            headers: req.headers.set('Authorization', `bearer ${this.auth2.userInfo && this.auth2.userInfo.Token ? this.auth2.userInfo.Token : ''}`),
            responseType: 'text',
          });
          return next.handle(newReq).pipe(
            timeout(30000),
            // 错误网络层面处理
            catchError(this.handleError),
          );
        // 浏览器免登陆
        case 'auto_login':
          newReq = req.clone({
            url: `${req.url.split('?')[ 0 ]}`,
            headers: req.headers.set('crs-cookie', localStorage.getItem('crs_cookie'))
          });
          return next.handle(newReq).pipe(
            timeout(30000),
            // 错误网络层面处理
            catchError(this.handleErrorLogin),
          );
          //  登陆操作
        case 'login':
          newReq = req.clone({
            url: `${req.url.split('?')[ 0 ]}`, 
            headers: req.headers.set('Authorization', `bearer ${this.auth2.userInfo && this.auth2.userInfo.Token ? this.auth2.userInfo.Token : ''}`),
          });
          return next.handle(newReq).pipe( 
            // 错误网络层面处理
            catchError(this.handleErrorLogin),
            tap(this.handleBusinessError),
          );
      }
    } else {
      // 基本处理方案
      const newReq = req.clone({
        // 处理请求的url进行拼接工作
        url: `${req.url.split('?')[ 0 ]}`,
        // 此处设置额外的头部，token常用于登陆令牌
        headers: req.headers.set('Authorization', `bearer ${this.auth2.userInfo && this.auth2.userInfo.Token ? this.auth2.userInfo.Token : ''}`),
      });
      return next.handle(newReq)
        .pipe(
          //jira1221去掉前端请求后端30s无响应重试功能，去掉timeout(30000)和retry(2)
          // 超时时间（ms）, 这个必须被放在retry前面
          // timeout(30000),
          // // 失败时重新尝试次数
          // retry(2),
          // 错误网络层面处理
          catchError(this.handleError),
          // 处理业务层面错误
          tap(this.handleBusinessError),
        );
    }
  }
  private handleErrorLogin = ( error: any ) => {
    if ( error instanceof HttpErrorResponse ) {
      if ( error.status === 401 ) {
        if ( this.auth2.isOATH ) {
          this.router.navigate([ 'index/error/401' ]);
        } else {
          if ( env.name !== 'saas' ) {
            localStorage.removeItem('crs_cookie');
            window.location.reload();
          } else {
            this.auth2.jumpToken();
          }
        }
      } else {
        this.notify.blank(this.translate.instant('message.notify_interceptorLogin'), error.message, {
          nzDuration: 0,
          nzAnimate: true,
          nzStyle: {
            color: 'red'
          }
        });
      }
    } else {
      this.notify.blank(this.translate.instant('message.notify_interceptorLogin'), error.toString(), {
        nzDuration: 0,
        nzAnimate: true,
        nzStyle: {
          color: 'red'
        }
      });
    }
    return throwError(error);
  }
  // 这里做一些网络上的报错处理，例如400，401这一类的处理
  private handleError = ( error: any ) => {
    if ( error instanceof HttpErrorResponse ) {
      if ( error.status === 401 ) {
        if ( this.auth2.isOATH ) {
          this.router.navigate([ 'index/error/401' ]);
        } else {
          if ( env.name !== 'saas' ) {
            localStorage.removeItem('crs_cookie');
            window.location.reload();
          } else {
            this.auth2.jumpToken();
          }
        }
      } else {
        this.notify.blank(this.translate.instant('message.notify_interceptor'), error.message, {
          nzDuration: 0,
          nzAnimate: true,
          nzStyle: {
            color: 'red'
          }
        });
      }
    } else {
      this.notify.blank(this.translate.instant('message.notify_interceptor'), error.toString(), {
        nzDuration: 0,
        nzAnimate: true,
        nzStyle: {
          color: 'red'
        }
      });
    }
    return throwError(error);
  };

  private handleBusinessError = ( data: any ): any => {
    if ( data instanceof HttpResponse && data.body.hasOwnProperty('Result') && data.body.hasOwnProperty('Object')
      && data.body.hasOwnProperty('ErrorMsg') && data.body.hasOwnProperty('ErrorCode') ) {
      if ( data.body.Result === 0 && UNWANTED_ERROR_CODE.indexOf(data.body.ErrorCode) === -1 ) {
        this.modalService.error({
          nzTitle: this.translate.instant('message.tip'),
          nzContent: `${this.translate.instant('errorcode.' + data.body.ErrorCode)}${this.translate.instant('errorcode.cat')}${data.body.ErrorCode}`,
        });
      }
    }
    return data;
  };

  private handleBusinessErrorCRM = ( data: any ): any => {
    if ( data instanceof HttpResponse && data.body.hasOwnProperty('Result') && data.body.hasOwnProperty('Object')
      && data.body.hasOwnProperty('ErrorMsg') && data.body.hasOwnProperty('ErrorCode') ) {
      if ( data.body.Result === 0 ) {
        this.modalService.error({
          nzTitle: this.translate.instant('message.tip'),
          nzContent: `${data.body.ErrorMsg}`,
        });
      }
    }
    return data;
  };
}
