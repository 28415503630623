import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth2Service } from './auth2.service';

@Injectable( {
  providedIn: 'root',
} )
export class AuthGuard implements CanActivate {

  forbidden_url = [ '/index/error/401', '/index/error/403', '/index/error/500', '/protect' ];

  constructor( private router: Router, private auth: Auth2Service ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkLogin( url );
  }

  checkLogin( url: string ): boolean {
    if ( this.auth.isLogIn ) {
      return true;
    }
    this.setTempRedirectUrl( url );
    this.router.navigate( [ '/login' ] );
    return false;
  }

  // 由于存在用户可能对地址栏输入行为，所以在这里临时保存一下用户想直接进入的页面，这样就可以保证在登陆之后直接跳转套对应页面
  setTempRedirectUrl( url: string ) {
    if ( this.forbidden_url.indexOf( url ) === -1 ) {
      this.auth.tempRedirectUrl = url;
    }
  }
}
