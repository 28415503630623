import { NgModule } from '@angular/core';
import { NZ_ICONS } from 'ng-zorro-antd';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  UserOutline,
  LockOutline,
  ExclamationCircleOutline,
  CheckCircleFill,
  ClockCircleFill,
  CheckOutline,
  CloseOutline,
  SaveOutline,
  EditOutline,
  RollbackOutline,
  SearchOutline,
  RetweetOutline,
  FileAddOutline,
  DeleteOutline,
  RedoOutline,
  UploadOutline,
  FileImageOutline,
  VerticalLeftOutline,
  VerticalRightOutline,
  DoubleLeftOutline,
  DoubleRightOutline,
  LoginOutline,
  LayoutOutline,
  FullscreenOutline,
  FullscreenExitOutline,
  CopyOutline,
  CalendarOutline,
  BarsOutline,
  DollarOutline,
  PlusOutline,
  MinusOutline,
} from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [
  UserOutline,
  LockOutline,
  ExclamationCircleOutline,
  CheckCircleFill,
  ClockCircleFill,
  CheckOutline,
  CloseOutline,
  SaveOutline,
  RollbackOutline,
  EditOutline,
  SearchOutline,
  RetweetOutline,
  FileAddOutline,
  DeleteOutline,
  RedoOutline,
  UploadOutline,
  FileImageOutline,
  VerticalLeftOutline,
  VerticalRightOutline,
  DoubleLeftOutline,
  DoubleRightOutline,
  LoginOutline,
  LayoutOutline,
  FullscreenOutline,
  FullscreenExitOutline,
  CopyOutline,
  CalendarOutline,
  BarsOutline,
  DollarOutline,
  PlusOutline,
  MinusOutline,
];

@NgModule({
  providers: [
    { provide: NZ_ICONS, useValue: icons },
  ]
})
export class IconsProviderModule {
}
