import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  preLoaderEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  preLoaderEventEmitter() {
    this.preLoaderEvent.emit();
  }
}
