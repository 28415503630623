import * as Crypto from 'crypto-js';
import sha256 from 'crypto-js/sha256';
import { env } from '../config/Config';
import { MultiLanguage } from '../services/models/MultiLanguage';

// GUID,唯一标识
export const GUID = (): string => {
  // tslint:disable-next-line:no-bitwise
  const s4 = (): string => ((((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1));
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

// 加密
export const encrypt = ( userName: string | number, password: string, TimeStamp: string, encryptType: 'login'| 'updatePassword' ): string => {
  // if (encryptType === 'login') {
  //   const hashDigest = sha256(password).toString();
  //   const hmacDigest = sha256(`CRS${userName + hashDigest + TimeStamp}CRS`).toString();
  //   return userName && password ? hmacDigest : '';
  // }
  // if (encryptType === 'updatePassword') {
    if (typeof userName ===  'number') {
      userName = userName.toString()
    }
    const str = userName + TimeStamp;
    const key = sha256(str).toString().substring(0, 32);
    const cryptokey = Crypto.enc.Utf8.parse(key);
    const result = Crypto.enc.Base64.stringify((Crypto.AES.encrypt(password, cryptokey, {
      mode: Crypto.mode.ECB,
      padding: Crypto.pad.Pkcs7,
    }).ciphertext));
    return userName && password ? result : '';
  // }
};

// 显示是pc还是mobile
export const isMobileOrPc = () => {
  if ( (navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
  ) {
    console.log('mobile');
  } else {
    console.log('pc');
  }
};

// 日期格式转换
export const formatDate = ( input: Date | string, option?: string ): any => {
  if ( !input ) {
    return '';
  }
  const _date = new Date(input);
  switch ( option ) {
    case 'locale-date': // 格式 yyyy-M-d
      return _date.toLocaleDateString();
    case 'locale-time': // 格式 HH:mm:ss
      return _date.toLocaleTimeString();
    case 'YYYY-MM-01': // 每月1号
      return _date.getFullYear() + '-' + ((_date.getMonth() + 1) > 9 ?
        (_date.getMonth() + 1) : '0' + (_date.getMonth() + 1)) + '-'
        + '01';
    case 'YYYY-MM-LAST': // 每月最后一天
      const last = _date.getMonth() + 2 <= 12 ? (
        _date.getFullYear() + '-' + ((_date.getMonth() + 2) > 9 ? (_date.getMonth() + 2) : '0' + (_date.getMonth() + 2)) + '-' + '01'
      ) : (
        (_date.getFullYear() + 1) + '-' + '01' + '-' + '01'
      );
      const __date = new Date(new Date(last).getTime() - 60 * 1000 * 60 * 24);
      return __date.getFullYear() + '-' + ((__date.getMonth() + 1) > 9 ? (__date.getMonth() + 1) :
        '0' + (__date.getMonth() + 1)) + '-' + (__date.getDate() > 9 ? __date.getDate() : '0' + __date.getDate());
    case 'next15': // 下月15号
      let yearN = _date.getFullYear();
      let monthN = _date.getMonth() + 2;
      if ( monthN === 13 ) {
        yearN++;
        monthN = 1;
      }
      return yearN + '-' + (monthN > 9 ? monthN : '0' + monthN) + '-' + '15';
    case 'prev15': // 上月15号
      let yearP = _date.getFullYear();
      let monthP = _date.getMonth();
      if ( monthP === 0 ) {
        yearP--;
        monthP = 12;
      }
      return yearP + '-' + (monthP > 9 ? monthP : '0' + monthP) + '-' + '15';
    case 'time':
      return new Date(_date.getFullYear() + '-' + ((_date.getMonth() + 1) > 9 ? (_date.getMonth() + 1) :
        '0' + (_date.getMonth() + 1)) + '-' + (_date.getDate() > 9 ? _date.getDate() : '0' + _date.getDate())).getTime();
    case 'getTime': // 格式 YYYY-MM-DD
      return _date.getFullYear() + '-' + ((_date.getMonth() + 1) > 9 ? (_date.getMonth() + 1) :
        '0' + (_date.getMonth() + 1)) + '-' + (_date.getDate() > 9 ? _date.getDate() : '0' + _date.getDate()) + ' ' + (_date.getHours() > 9 ? _date.getHours() : '0' + _date.getHours()) + ':' + (_date.getMinutes() > 9 ? _date.getMinutes() : '0' + _date.getMinutes()) + ':' + (_date.getSeconds() > 9 ? _date.getSeconds() : '0' + _date.getSeconds());
    default: // 格式 YYYY-MM-DD
      return _date.getFullYear() + '-' + ((_date.getMonth() + 1) > 9 ? (_date.getMonth() + 1) :
        '0' + (_date.getMonth() + 1)) + '-' + (_date.getDate() > 9 ? _date.getDate() : '0' + _date.getDate());
  }
};

// 多语言转换
export const formatMultiLanguage = ( arr: MultiLanguage[], language: string ): string => {
  let target = '';
  if ( arr ) {
    arr.map(item => {
      if ( item.Language === language ) {
        target = item.Content;
      }
    });
  }
  return target;
};

// 本地语言转换
export const formatLocalLanguage = (): string => {
  let local = localStorage.getItem('crs_language');
  if ( !local ) {
    local = 'zh_CN';
  }
  switch ( local ) {
    case 'zh_CN':
      return 'CN';
    case 'en_US':
      return 'EN';
  }
};

export const localLanguage = (): string => {
  return localStorage.getItem('crs_language') ? localStorage.getItem('crs_language') : 'zh_CN';
};

// 周控转换
export const formatWeeklyControl = ( WeeklyControl: {
  Su: boolean;
  Mo: boolean;
  Tu: boolean;
  We: boolean;
  Th: boolean;
  Fr: boolean;
  Sa: boolean;
} | string ): string => {
  let str = '';
  if ( typeof WeeklyControl !== 'string' ) {
    WeeklyControl.Su ? str += '1' : str += '0';
    WeeklyControl.Mo ? str += '1' : str += '0';
    WeeklyControl.Tu ? str += '1' : str += '0';
    WeeklyControl.We ? str += '1' : str += '0';
    WeeklyControl.Th ? str += '1' : str += '0';
    WeeklyControl.Fr ? str += '1' : str += '0';
    WeeklyControl.Sa ? str += '1' : str += '0';
  } else {
    str = WeeklyControl;
  }
  return str;
};

// LOS转换
export const formatLOSControl = ( LOS: any ): string => {
  let str = '';
  LOS[ '1' ] ? str += '1' : str += '0';
  LOS[ '2' ] ? str += '1' : str += '0';
  LOS[ '3' ] ? str += '1' : str += '0';
  LOS[ '4' ] ? str += '1' : str += '0';
  LOS[ '5' ] ? str += '1' : str += '0';
  LOS[ '6' ] ? str += '1' : str += '0';
  LOS[ '7' ] ? str += '1' : str += '0';
  return str;
};

// 全局不可用的时间
export const disabledDate = ( current: Date ): boolean => {
  return new Date().getTime() - (60 * 60 * 1000 * 24) > current.getTime();
};
// 全局不可用的时间-当日前一天
export const disabledDatePre = ( current: Date ): boolean => {
  return new Date().getTime() - (60 * 60 * 1000 * 48) > current.getTime();
};

// 重构查询日历返回的数据
export const rebuildCalendarData = ( data: any ): {
  Year: number;
  Month: number;
  Day: number;
  Data: any;
}[] => {
  return data.map(item => ({
    Year: new Date(item.Date).getFullYear(),
    Month: new Date(item.Date).getMonth() + 1,
    Day: new Date(item.Date).getDate(),
    Data: item
  }));
};

// 根据不同字段给数组分组
export const groupBy = (array: any[], f: Function) => {
  const groups = {};
  array.map(o => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(group => groups[group]);
}
