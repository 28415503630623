import { HttpParams } from '@angular/common/http';

export interface ApiBody {
  SignInfoObj: {
    TimeStamp: number;
    Sign: string;
  };
  BusinessType: string;
  BusinessInfo?: any;
  Language?: string;
  OperatorId?: string;
}

export interface ResponseBody {
  Result: number;
  Object: any;
  ErrorMsg: string;
  ErrorCode: number;
}

export class Api {

  constructor( business_type: string ) {
    this.SignInfoObj = Api.getSignInfoObj();
    this.BusinessType = business_type;
  }

  private SignInfoObj: {
    TimeStamp: number;
    Sign: string;
  };
  private BusinessType: string;

  private static getSign( date: Date ): string {
    return date.toLocaleString();
  }

  private static getSignInfoObj(): { TimeStamp: number; Sign: string; } {
    const date: Date = new Date();
    return {
      TimeStamp: date.getTime(),
      // Sign: Api.getSign( date ),
      Sign: '',
    };
  }

  // post｜put
  modify( param: any, key?: string ): ApiBody {
    if ( key ) {
      switch ( key ) {
        case 'ModelList':
          return {
            BusinessType: '',
            SignInfoObj: {
              Sign: '',
              TimeStamp: 0,
            },
            ...this,
            ...param,
          };
        case  'BusinessInfo':
          return {
            BusinessType: '',
            SignInfoObj: {
              Sign: '',
              TimeStamp: 0,
            },
            ...this,
            ...param,
          };
      }
    }
    return {
      BusinessType: '',
      SignInfoObj: {
        Sign: '',
        TimeStamp: 0,
      },
      ...this,
      BusinessInfo: param,
    };
  }

  // 房量特殊接口
  modifyInventory( param: any, operator: string ): ApiBody {
    return {
      BusinessType: '',
      SignInfoObj: {
        Sign: '',
        TimeStamp: 0,
      },
      ...this,
      BusinessInfo: param,
      OperatorId: operator,
    };
  }

  // get
  get( param: any, language?: string ): HttpParams | {
    [ param: string ]: string | string[];
  } {
    const reBuild: ApiBody = {
      BusinessType: '',
      SignInfoObj: {
        Sign: '',
        TimeStamp: 0,
      },
      ...this,
      Language: language ? language : 'CN',
      ...param, // 这个参数可以不用声明，因为接口只定义了必须要有什么
    };
    return { param: JSON.stringify(reBuild) };
  }

  // 用于查询的post接口
  postGet( param: any, language?: string ): ApiBody {
    return {
      BusinessType: '',
      SignInfoObj: {
        Sign: '',
        TimeStamp: 0,
      },
      ...this,
      Language: language ? language : 'CN',
      ...param,
    };
  }

  // 删除
  delete( param: any ): HttpParams | {
    [ param: string ]: string | string[];
  } {
    const reBuild: ApiBody = {
      BusinessType: '',
      SignInfoObj: {
        Sign: '',
        TimeStamp: 0,
      },
      ...this,
      ...param,
    };
    return { param: JSON.stringify(reBuild) };
  }
}
