import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationError, RouteConfigLoadEnd, Router } from '@angular/router';
import { PRE_LOAD_MODULE } from './utils/constants';
import { DOCUMENT } from '@angular/common';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd';
import { LoginService } from './pages/login/index/login.service';
import { Auth2Service } from './services/auth/auth2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.less' ]
})
export class AppComponent {

  constructor( private translate: TranslateService,
               private nzI18nService: NzI18nService,
               private router: Router,
               private renderer: Renderer2,
               private el: ElementRef,
               @Inject(DOCUMENT) private doc: any,
               private login: LoginService,
               private auth2: Auth2Service, ) {
    console.log('App is running!');
    // 原生方式判断PC|移动端
    // isMobileOrPc();
    // 设置语言
    translate.setDefaultLang('zh_CN');
    const lang = localStorage.getItem('crs_language') ? localStorage.getItem('crs_language') : 'zh_CN';
    localStorage.setItem('crs_language', lang);
    translate.use(lang);
    switch ( lang ) {
      case 'en_US':
        this.nzI18nService.setLocale(en_US);
        break;
      case 'zh_CN':
        this.nzI18nService.setLocale(zh_CN);
        break;
    }
    // 设置预加载界面的loading状态，附带渐隐动画效果
    let alreadyLoadModule = 0;
    router.events.subscribe(value => {
      if ( value instanceof RouteConfigLoadEnd ) {
        // console.log(value.route.path);
        if ( PRE_LOAD_MODULE.indexOf(value.route.path) > -1 ) {
          alreadyLoadModule++;
        }
        // console.log(alreadyLoadModule);
        if ( alreadyLoadModule === PRE_LOAD_MODULE.length ) {
          this.login.preLoaderEventEmitter();
          this.renderer.setAttribute(doc.getElementById('preloader'),
            'class', 'preloader preloader-hidden-add preloader-hidden-add-active');
          setTimeout(() => {
            this.renderer.setAttribute(doc.getElementById('preloader'),
              'class', 'preloader-hidden');
          }, 650);
        }
      }

      if ( value instanceof NavigationError ) {
        if ( auth2.isLogIn ) {
          this.router.navigate([ '/index/error/404' ]);
        } else {
          this.router.navigate([ '/404' ]);
        }
      }
    });
  }
}
