import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GUID } from '../../utils/utils';
import { Config } from '../../config/Config';
import { Api, ResponseBody } from '../models/Api';
import { User, Login, LoginParam, SelectTree, LoginSSOParam, LoginSSO } from './models/User';
import { PropertyTree } from '../crs/origanization/models/Property';

@Injectable({
  providedIn: 'root',
})
export class Auth2Service {

  private Login = 'Login';
  private LoginOut = 'LogOut';
  private SSOLogin = 'SSOLogin';
  private UserInfo = 'User/UserInfo';

  // 登陆状态, 利用Subject(多播)监听登陆状态的改变做一些事情,例如在用户登录之后进行预加载其他模块内容,当前暂时不用
  private _isLogIn = false;
  isLoginInUpdated: Subject<boolean> = new Subject<boolean>();
  // 想要直接渠道的页面url
  tempRedirectUrl: string;
  userInfo: User | null;
  // GUID
  private GUID: string;
  private _selectTree: SelectTree | null;
  // 用户是否通过认证登入到前端
  private _isOATH = false;
  // pTree
  private _pTree: PropertyTree;

  constructor( private http: HttpClient ) {
  }

  get pTree(): PropertyTree {
    return this._pTree;
  }

  set pTree( value: PropertyTree ) {
    this._pTree = value;
  }

  get isLogIn(): boolean {
    return this._isLogIn;
  }

  set isLogIn( value: boolean ) {
    this._isLogIn = value;
    this.isLoginInUpdated.next(value);
  }

  get selectTree(): SelectTree | null {
    return this._selectTree;
  }

  set selectTree( value: SelectTree | null ) {
    this._selectTree = value;
  }

  get isOATH(): boolean {
    return this._isOATH;
  }

  set isOATH( value: boolean ) {
    this._isOATH = value;
  }

  login( param: Login, auto: boolean ): Observable<any> {
    return this.http.post(
      `${new Config('auth').url}${this.Login}${auto ? '?auto_login' : '?login'}`,
      new Api('Login').modify(new LoginParam(param, this.GUID)),
    );
  }

  // 查询用户信息接口
  getUserInfo(): Observable<any> {
    return this.http.get(
      `${new Config('auth').url}${this.UserInfo}`,
    );
  }

  // 用户登出
  logOut(): void {
    this.isLogIn = false;
    localStorage.removeItem('crs-cookie');
    this.http.post(
      `${new Config('auth').url}${this.Login}/${this.LoginOut}`,
      new Api('mdm_user').modify({ token: this.userInfo.Token }),
    ).subscribe(( res: ResponseBody ) => {
      if ( res.Result === 1 ) {
        window.location.reload();
      }
    });
  }

  // 获取验证码
  getVerify(): Observable<any> {
    this.GUID = GUID();
    return this.http.get(
      `${new Config('auth').url}${this.Login}/${this.GUID}`,
    );
  }

  // login sso形式
  loginSSO( param: LoginSSO ): Observable<any> {
    return this.http.post(
      `${new Config('auth').url}${this.SSOLogin}${'?login'}`,
      new Api('GETUSERINFO').modify(new LoginSSOParam(param)),
    );
  }

  // 登出
  loginSSOOut(): void {
    this.isLogIn = false;
    window.location.href = `${new Config('auth').url}${this.SSOLogin}/${this.LoginOut}?token=${this.userInfo.Token}`;
  }

  // 跳转获取token
  jumpToken(): void {
    window.location.href = `${new Config('auth').url}${this.SSOLogin}`;
  }
}
