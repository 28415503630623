import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { httpInterceptorProviders } from './providers/http-interceptors';
import { IconsProviderModule } from './providers/icons-provider/icons-provider.module';
import { Auth2Service } from './services/auth/auth2.service';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { GuardsCheckEnd, NavigationEnd } from '@angular/router';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

import { AppComponent } from './app.component';
import { ProtectComponent } from './pages/index/protect/protect.component';

// 注入部分需要用到Angular自带的多语言组件
registerLocaleData(zh);

export function HttpLoaderFactory( http: HttpClient ) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent, ProtectComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    AppRoutingModule,
    IconsProviderModule,
    NgProgressModule.withConfig({
      trickleSpeed: 400,
      color: '#1890ff',
      min: 40,
      speed: 300,
    }),
    NgProgressRouterModule.withConfig({
      id: 'router-progress',
      startEvents: [ GuardsCheckEnd ],
      completeEvents: [ NavigationEnd ],
      delay: 50,
    }),
    NgProgressHttpModule.withConfig({
      id: 'http-progress',
      silentApis: [], // 配置在这里的API不会显示进度条
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [ HttpClient ]
      }
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    httpInterceptorProviders,
    CookieService,
    Auth2Service,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
