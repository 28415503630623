// 预加载的模块名称
export const PRE_LOAD_MODULE = [
  // 基本模块
  'index', 'home', // 'user', 'setting', 'reports',
  // CRS
  'rates', // 'availability', 'organization',  'setup', 'inventorys',
  // CRO
  'reservations',  // 'profiles', 'resvconfig',
  // CCM
  // 'chnlconfig', 'datamapping',
  // Report
  // 'gaos', 'haos', 'hms'
];

// 不需要在拦截器处理的接口错误代码
export const UNWANTED_ERROR_CODE = [43, 103, 7001, 7002, 7003, 7004];

// 路由基础信息,路由列表
export const ROUTE = [
  {route: '/index/home/standard', id: '1000100001', nav: 'home'},
  {route: '/index/home/crs', id: '1000100002', nav: 'crs'},
  {route: '/index/home/cro', id: '1000100003', nav: 'cro'},
  {route: '/index/home/ccm', id: '1000100004', nav: 'ccm'},
  {route: '/index/home/report', id: '1000100005', nav: 'report'},
  {route: '/index/reports/reports', id: '6000100001', nav: 'reports'},
  {route: '/index/setting/setting', id: '6000100002', nav: 'setting'},
  {route: '/index/setting/users', id: '6000100003', nav: 'users'},
  {route: '/index/setting/security', id: '6000100004', nav: 'security'},
  {route: '/index/reports/interfacelog', id: '6000100005', nav: 'interfacelog'},
  {route: '/index/reports/optionlog', id: '6000100006', nav: 'optionlog'},
  {route: '/index/system-param/index', id: '6000100007', nav: 'system_config'},
  {route: '/index/organization/organization', id: '2000100001', nav: 'organization'},
  {route: '/index/rates/rates', id: '2000100002', nav: 'ratesmanagement'},
  {route: '/index/availability/availability', id: '2000100003', nav: 'availability'},
  {route: '/index/setup/setup', id: '2000100004', nav: 'setup'},
  {route: '/index/reservations/reservations', id: '2000100005', nav: 'reservations'},
  {route: '/index/profiles/profiles', id: '2000100006', nav: 'profiles'},
  {route: '/index/resvconfig/resvconfig', id: '2000100007', nav: 'resvconfiguration'},
  {route: '/index/chnlconfig/chnlconfig', id: '2000100008', nav: 'chnlconfiguration'},
  {route: '/index/datamapping/datamapping', id: '2000100009', nav: 'datamapping'},
  {route: '/index/configuration/configuration', id: '2000100015', nav: 'configuration'},
  {route: '/index/inventorys/inventorys-home', id: '2000100010', nav: 'inventorycontrol'},
  {route: '/index/gaos/gaos-home', id: '2000100011', nav: 'gaos'},
  {route: '/index/haos/haos-home', id: '2000100012', nav: 'haos'},
  {route: '/index/hms/hms-home', id: '2000100013', nav: 'ghms'},
  {route: '/index/organization/chain', id: '3000100001', nav: 'chain'},
  {route: '/index/organization/brands', id: '3000100002', nav: 'brands'},
  {route: '/index/organization/properties', id: '3000100003', nav: 'properties'},
  {route: '/index/rates/roomtypes', id: '3000100004', nav: 'roomtypes'},
  {route: '/index/rates/rateplans', id: '3000100005', nav: 'rateplans'},
  {route: '/index/rates/calendarview', id: '3000100006', nav: 'calendarview'},
  {route: '/index/rates/packages', id: '3000100007', nav: 'packages'},
  {route: '/index/rates/taxes', id: '3000100008', nav: 'taxes'},
  // { route: '/index/inventorys/inventory', id: '3000100009', nav: 'inventorycontrol' },
  {route: '/index/availability/restriction', id: '3000100010', nav: 'restriction'},
  {route: '/index/availability/policy', id: '3000100011', nav: 'policy'},
  {route: '/index/setup/roomclasses', id: '3000100012', nav: 'roomclasses'},
  {route: '/index/setup/ratecategories', id: '3000100013', nav: 'ratecategories'},
  {route: '/index/setup/marketsegmentations-tier', id: '3000100014', nav: 'marketsegmentations'},
  {route: '/index/setup/channelcodes-tier', id: '3000100015', nav: 'channelcodes'},
  {route: '/index/setup/bookingsources-tier', id: '3000100016', nav: 'bookingsources'},
  {route: '/index/setup/guarantee', id: '3000100017', nav: 'guaranteerules'},
  {route: '/index/setup/cancellation', id: '3000100018', nav: 'cancellationrules'},
  {route: '/index/reservations/search', id: '3000100019', nav: 'search'},
  {route: '/index/reservations/new', id: '3000100020', nav: 'newaresv'},
  {route: '/index/reservations/modify', id: '3000100031', nav: 'modifyresv'},
  {route: '/index/profiles/profile', id: '3000100021', nav: 'profile'},
  {route: '/index/profiles/rsoowner', id: '3000100022', nav: 'rsoowner'},
  {route: '/index/resvconfig/letter', id: '3000100023', nav: 'confirmationletter'},
  {route: '/index/resvconfig/resvtype', id: '3000100024', nav: 'resvtype'},
  {route: '/index/chnlconfig/channel', id: '3000100025', nav: 'channel'},
  {route: '/index/datamapping/ota', id: '3000100026', nav: 'otamapping'},
  {route: '/index/configuration/ctripmapping', id: '3000100077', nav: 'ctripmapping'},
  {route: '/index/configuration/ctripmapping', id: '3000100078', nav: 'channelHotelDistrIbution'},
  {route: '/index/datamapping/pms', id: '3000100027', nav: 'pmsmapping'},
  // { route: '/index/rates/modifiersets', id: '3000100028', nav: 'modifiersets' },
  // { route: '/index/setup/ref11th-tier', id: '3000100029', nav: 'ref11thedition' },
  {route: '/index/resvconfig/special-request', id: '3000100030', nav: 'specialrequest'},
  // { route: '/index/setup/room-additional', id: '3000100031', nav: 'roomadditional' },
  {route: '/index/rates/roomtype', id: '3000100032', nav: 'roomtypes'},
  {route: '/index/inventorys/roll', id: '3000100033', nav: 'rollinvalue'},
  {route: '/index/inventorys/inventory-control', id: '3000100034', nav: 'inventorycontrol'},
  {route: '/index/inventorys/batch', id: '3000100035', nav: 'batch'},
  {route: '/index/rates/rateplan', id: '3000100036', nav: 'rateplans'},
  {route: '/index/rates/room-modifier', id: '3000100037', nav: 'roomtypemodifiers'},
  {route: '/index/rates/person-modifier', id: '3000100038', nav: 'personmodifiers'},
  {route: '/index/hms/ghms', id: '3000100039', nav: 'ghms'},
  {route: '/index/gaos/marketsegment', id: '3000100040', nav: 'gmarketsegment'},
  {route: '/index/gaos/channelcode', id: '3000100041', nav: 'gchannelcode'},
  {route: '/index/gaos/sourcecode', id: '3000100042', nav: 'gsourcecode'},
  {route: '/index/gaos/companyprofile', id: '3000100043', nav: 'gcompanyprofile'},
  {route: '/index/gaos/taprofile', id: '3000100044', nav: 'gtaprofile'},
  {route: '/index/haos/marketsegment', id: '3000100045', nav: 'hmarketsegment'},
  {route: '/index/haos/channelcode', id: '3000100046', nav: 'hchannelcode'},
  {route: '/index/haos/sourcecode', id: '3000100047', nav: 'hsourcecode'},
  {route: '/index/haos/companyprofile', id: '3000100048', nav: 'hcompanyprofile'},
  {route: '/index/haos/taprofile', id: '3000100049', nav: 'htaprofile'},
  {route: '/index/reservations/direct', id: '3000100050', nav: 'directbooking'},
  {route: '/index/setup/grouproomtype', id: '3000100051', nav: 'grouproomtype'},
  {route: '/index/setup/grouprateplan', id: '3000100052', nav: 'grouprateplan'},
  {route: '/index/setup/grouppackages', id: '3000100053', nav: 'grouppackages'},
  {route: '/index/setup/moduleassignment', id: '3000100054', nav: 'moduleassignment'},
  {route: '/index/setup/cancelreason', id: '3000100055', nav: 'cancelreason'},
  {route: '/index/setup/title', id: '3000100056', nav: 'title'},
  {route: '/index/rates/dailyreport', id: '3000100057', nav: 'dailyreport'},
  {route: '/index/availability/roomstatus', id: '3000100058', nav: 'roomstatus'},
  {route: '/index/setup/alert', id: '3000100059', nav: 'alert'},
  {route: '/index/hms/gor', id: '3000100060', nav: 'gor'},
  {route: '/index/setup/industry', id: '3000100061', nav: 'industry'},
  {route: '/index/setup/actionstatus', id: '3000100062', nav: 'actionstatus'},
  {route: '/index/setup/businesssegment', id: '3000100063', nav: 'businesssegment'},
  {route: '/index/setup/accounttype', id: '3000100064', nav: 'accounttype'},
  {route: '/index/setup/bookingcode', id: '3000100065', nav: 'bookingcode'},
  {route: '/index/profiles/individual', id: '3000100066', nav: 'individual'},
  {route: '/index/setup/pay', id: '3000100067', nav: 'pay'},
  {route: '/index/setup/approval', id: '3000100068', nav: 'approval'},
  {route: '/index/setup/discountreason', id: '3000100069', nav: 'discountreason'},
  {route: '/index/resvconfig/transport', id: '3000100070', nav: 'transport'},
  {route: '/index/rates/calendar-tier', id: '3000100071', nav: 'calendartier'},
  {route: '/index/setup/paymenttype', id: '3000100072', nav: 'paymenttype'},
  {route: '/index/rates/child-rateplan', id: '3000100073', nav: 'childrateplan'},
  {route: '/index/setup/meal-plan', id: '3000100074', nav: 'mealplan'},

];

// 系统首页,可能会存在两个
export const SYSTEM_HOME = [
  // Standard首页(部署)
  {
    key: '1000100001',
    title: 'Standard首页',
    url: '/index/home/standard',
  },
];

// 路由树节点_基础
export const ROUTE_TREE_BASE = [
  {
    key: '1000100002',
    title: 'nav.crs',
    module: 'crs',
    url: '/index/home/crs',
    children: [
      {
        key: '2000100001',
        title: 'nav.organization',
        url: '/index/organization/organization',
        children: [
          {
            key: '3000100001',
            title: 'nav.chain',
            url: '/index/organization/chain',
          },
          {
            key: '3000100002',
            title: 'nav.brands',
            url: '/index/organization/brands',
          },
          {
            key: '3000100003',
            title: 'nav.properties',
            url: '/index/organization/properties',
          }
        ]
      },
      {
        key: '2000100002',
        title: 'nav.ratesmanagement',
        url: '/index/rates/rates',
        children: [
          {
            key: '3000100032',
            title: 'nav.roomtypes',
            url: '/index/rates/roomtype',
          },
          {
            key: '3000100037',
            title: 'nav.roomtypemodifiers',
            url: '/index/rates/room-modifier',
          },
          {
            key: '3000100038',
            title: 'nav.personmodifiers',
            url: '/index/rates/person-modifier',
          },
          {
            key: '3000100036',
            title: 'nav.rateplans',
            url: '/index/rates/rateplan',
          },
          {
            key: '3000100071',
            title: 'nav.calendartier',
            url: '/index/rates/calendar-tier',
          },
          {
            key: '3000100057',
            title: 'nav.dailyreport',
            url: '/index/rates/dailyreport',
          },
          {
            key: '3000100007',
            title: 'nav.packages',
            url: '/index/rates/packages',
          },
          {
            key: '3000100005',
            title: 'nav.rateplans',
            url: '/index/rates/rateplans',
          },
          // {
          //   key: '3000100028',
          //   title: 'nav.modifiersets',
          //   url: '/index/rates/modifiersets',
          // },
          {
            key: '3000100006',
            title: 'nav.calendarview',
            url: '/index/rates/calendarview',
          },
          {
            key: '3000100004',
            title: 'nav.roomtypes',
            url: '/index/rates/roomtypes',
          },
          // {
          //   key: '3000100073',
          //   title: 'nav.childrateplan',
          //   url: '/index/rates/child-rateplan',
          // },
        ]
      },
      {
        key: '2000100010',
        title: 'nav.inventorycontrol',
        url: '/index/inventorys/inventorys-home',
        children: [
          {
            key: '3000100034',
            title: 'nav.inventorycontrol',
            url: '/index/inventorys/inventory-control',
          },
          {
            key: '3000100033',
            title: 'nav.rollinvalue',
            url: '/index/inventorys/roll',
          },
          {
            key: '3000100035',
            title: 'nav.batch',
            url: '/index/inventorys/batch',
          },
          // {
          //   key: '3000100009',
          //   title: 'nav.inventorycontrol',
          //   url: '/index/inventorys/inventory',
          // },
        ]
      },
      {
        key: '2000100003',
        title: 'nav.availability',
        url: '/index/availability/availability',
        children: [
          {
            key: '3000100010',
            title: 'nav.restriction',
            url: '/index/availability/restriction',
          },
          {
            key: '3000100058',
            title: 'nav.roomstatus',
            url: '/index/availability/roomstatus',
          },
          {
            key: '3000100011',
            title: 'nav.policy',
            url: '/index/availability/policy',
          }
        ]
      },
      {
        key: '2000100004',
        title: 'nav.setup',
        url: '/index/setup/setup',
        children: [
          {
            key: '3000100012',
            title: 'nav.roomclasses',
            url: '/index/setup/roomclasses',
          },
          {
            key: '3000100051',
            title: 'nav.grouproomtype',
            url: '/index/setup/grouproomtype',
          },
          {
            key: '3000100052',
            title: 'nav.grouprateplan',
            url: '/index/setup/grouprateplan',
          },
          {
            key: '3000100013',
            title: 'nav.ratecategories',
            url: '/index/setup/ratecategories',
          },
          {
            key: '3000100014',
            title: 'nav.marketsegmentations',
            url: '/index/setup/marketsegmentations-tier',
          },
          {
            key: '3000100015',
            title: 'nav.channelcodes',
            url: '/index/setup/channelcodes-tier',
          },
          {
            key: '3000100016',
            title: 'nav.bookingsources',
            url: '/index/setup/bookingsources-tier',
          },
          {
            key: '3000100053',
            title: 'nav.grouppackages',
            url: '/index/setup/grouppackages',
          },
          {
            key: '3000100017',
            title: 'nav.guaranteerules',
            url: '/index/setup/guarantee',
          },
          {
            key: '3000100018',
            title: 'nav.cancellationrules',
            url: '/index/setup/cancellation',
          },
          {
            key: '3000100008',
            title: 'nav.taxes',
            url: '/index/rates/taxes',
          },
          {
            key: '3000100054',
            title: 'nav.moduleassignment',
            url: '/index/setup/moduleassignment',
          },
          // {
          //   key: '3000100031',
          //   title: 'nav.roomadditional',
          //   url: '/index/setup/room-additional',
          // },
          // {
          //   key: '3000100029',
          //   title: 'nav.ref11thedition',
          //   url: '/index/setup/ref11th-tier',
          // },
          {
            key: '3000100067',
            title: 'nav.pay',
            url: '/index/setup/pay',
          },
          {
            key: '3000100068',
            title: 'nav.approval',
            url: '/index/setup/approval',
          },
          {
            key: '3000100069',
            title: 'nav.discountreason',
            url: '/index/setup/discountreason',
          },
          {
            key: '3000100072',
            title: 'nav.paymenttype',
            url: '/index/setup/paymenttype',
          },
          {
            key: '3000100074',
            title: 'nav.meal-plan',
            url: '/index/setup/meal-plan',
          }
        ]
      }
    ]
  },
  {
    key: '1000100003',
    title: 'nav.cro',
    module: 'cro',
    url: '/index/home/cro',
    children: [
      {
        key: '2000100005',
        title: 'nav.reservations',
        url: '/index/reservations/reservations',
        children: [
          {
            key: '3000100019',
            title: 'nav.search',
            url: '/index/reservations/search',
          },
          {
            key: '3000100020',
            title: 'nav.newaresv',
            url: '/index/reservations/new',
          },
          {
            key: '3000100031',
            title: 'nav.modifyresv',
            url: '/index/reservations/modify',
          },
          {
            key: '3000100050',
            title: 'nav.directbooking',
            url: '/index/reservations/direct',
          }
        ]
      },
      {
        key: '2000100006',
        title: 'nav.profiles',
        url: '/index/profiles/profiles',
        children: [
          {
            key: '3000100021',
            title: 'nav.profile',
            url: '/index/profiles/profile',
          },
          {
            key: '3000100022',
            title: 'nav.rsoowner',
            url: '/index/profiles/rsoowner',
          },
        ]
      },
      {
        key: '2000100007',
        title: 'nav.resvconfiguration',
        url: '/index/resvconfig/resvconfig',
        children: [
          {
            key: '3000100056',
            title: 'nav.title',
            url: '/index/setup/title',
          },
          {
            key: '3000100055',
            title: 'nav.cancelreason',
            url: '/index/setup/cancelreason',
          },
          {
            key: '3000100024',
            title: 'nav.resvtype',
            url: '/index/resvconfig/resvtype',
          },
          {
            key: '3000100059',
            title: 'nav.alert',
            url: '/index/setup/alert',
          },
          {
            key: '3000100061',
            title: 'nav.industry',
            url: '/index/setup/industry',
          },
          {
            key: '3000100062',
            title: 'nav.actionstatus',
            url: '/index/setup/actionstatus',
          },
          {
            key: '3000100063',
            title: 'nav.businesssegment',
            url: '/index/setup/businesssegment',
          },
          {
            key: '3000100064',
            title: 'nav.accounttype',
            url: '/index/setup/accounttype',
          },
          {
            key: '3000100065',
            title: 'nav.bookingcode',
            url: '/index/setup/bookingcode',
          },
          {
            key: '3000100023',
            title: 'nav.confirmationletter',
            url: '/index/resvconfig/letter',
          },
          {
            key: '3000100030',
            title: 'nav.specialrequest',
            url: '/index/resvconfig/special-request',
          },
          {
            key: '3000100070',
            title: 'nav.transport',
            url: '/index/resvconfig/transport',
          },
        ]
      },
    ]
  },
  {
    key: '1000100004',
    title: 'nav.ccm',
    module: 'ccm',
    url: '/index/home/ccm',
    children: [
      {
        key: '2000100008',
        title: 'nav.chnlconfiguration',
        url: '/index/chnlconfig/chnlconfig',
        children: [
          {
            key: '3000100025',
            title: 'nav.channel',
            url: '/index/chnlconfig/channel',
          },
        ]
      },
      {
        key: '2000100009',
        title: 'nav.datamapping',
        url: '/index/datamapping/datamapping',
        children: [
          {
            key: '3000100026',
            title: 'nav.otamapping',
            url: '/index/datamapping/ota',
          },
          {
            key: '3000100027',
            title: 'nav.pmsmapping',
            url: '/index/datamapping/pms',
          },
        ]
      },
      {
        key: '2000100015',//2000100015
        title: 'channel.Configuration',
        url: '/index/configuration/configuration',
        children: [
          {
            key: '3000100077',
            title: 'nav.ctripmapping',
            url: '/index/configuration/ctripmapping',
          },
          {
            key: '3000100078',//3000100078
            title: 'nav.channelHotelDistrIbution',
            url: '/index/configuration/channelHotelDistrIbution',
          }
        ]
      },
    ]
  },
  {
    key: '1000100005',
    title: 'nav.report',
    module: 'report',
    url: '/index/home/report',
    children: [
      {
        key: '2000100013',
        title: 'nav.ghms',
        url: '/index/hms/hms-home',
        children: [
          {
            key: '3000100039',
            title: 'nav.ghms',
            url: '/index/hms/ghms',
          },
          {
            key: '3000100060',
            title: 'nav.gor',
            url: '/index/hms/gor',
          },
        ],
      },
      {
        key: '2000100011',
        title: 'nav.gaos',
        url: '/index/gaos/gaos-home',
        children: [
          {
            key: '3000100040',
            title: 'nav.gmarketsegment',
            url: '/index/gaos/marketsegment',
          },
          {
            key: '3000100041',
            title: 'nav.gchannelcode',
            url: '/index/gaos/channelcode',
          },
          {
            key: '3000100042',
            title: 'nav.gsourcecode',
            url: '/index/gaos/sourcecode',
          },
          {
            key: '3000100043',
            title: 'nav.gcompanyprofile',
            url: '/index/gaos/companyprofile',
          },
          {
            key: '3000100044',
            title: 'nav.gtaprofile',
            url: '/index/gaos/taprofile',
          },
        ],
      },
      {
        key: '2000100012',
        title: 'nav.haos',
        url: '/index/haos/haos-home',
        children: [
          {
            key: '3000100045',
            title: 'nav.hmarketsegment',
            url: '/index/haos/marketsegment',
          },
          {
            key: '3000100046',
            title: 'nav.hchannelcode',
            url: '/index/haos/channelcode',
          },
          {
            key: '3000100047',
            title: 'nav.hsourcecode',
            url: '/index/haos/sourcecode',
          },
          {
            key: '3000100048',
            title: 'nav.hcompanyprofile',
            url: '/index/haos/companyprofile',
          },
          {
            key: '3000100049',
            title: 'nav.htaprofile',
            url: '/index/haos/taprofile',
          },
        ],
      },
    ]
  },
];

// 路由树节点_日志
export const ROUTE_TREE_LOG = [
  {
    key: '6000100001',
    title: 'nav.reports',
    url: '',
    children: [
      {
        key: '6000100005',
        title: 'nav.interfacelog',
        url: '',
        children: []
      },
      {
        key: '6000100006',
        title: 'nav.optionlog',
        url: '',
        children: []
      },
    ]
  }
];

// 路由树节点_设置部分
export const ROUTE_TREE_SETTING = [
  {
    key: '6000100002',
    title: 'nav.setting',
    url: '',
    children: [
      {
        key: '6000100003',
        title: 'nav.users',
        url: '',
        children: []
      },
      {
        key: '6000100004',
        title: 'nav.security',
        url: '',
        children: []
      },
    ]
  }
];

// 路由树节点_系统参数部分
export const ROUTE_TREE_SYSTEM = [
  {
    key: '6000100007',
    title: 'nav.system_config',
    url: '',
    children: [],
  }
];
