import { Environment } from '../models/Environment';

const Test2: Environment = {
  name: 'test2',
  environment: 'standard',
  protocol: 'http://',
  // ip: '122.112.243.247',//测试退款接口-暂时-开发环境
  // picture_ip: '122.112.243.247:8443',
  // port: '5500',
  ip: 'yuncrs.mgit365.com',//test2的正牌域名
  picture_ip: 'yuncrs.mgit365.com:8443',
  port: '8088',
  pattern: 'api',
  version: '', 
  // gateway
  gateway: {
    spring_cloud: '/',
    log: '/log/',
    cache: '/',
    code: '/code_manage/',
    rate: '/rate/',
    mdm: '/mdm/',
    auth: '/userrole/',
    room_stay: '/inventory/',
    channel: '/channel/',
    order: '/order/',
    profile: '/profile/',
    interface: '/adapter/',
    safe: '/db/',
    crs: '/',
    ota: '/otaadapter/',
    message: '/messagesender/',
    report: '/report/',
  },
};
export default Test2;
