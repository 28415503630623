import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppInterceptor } from './app-interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
];


/*
* 内置令牌
* HTTP_INTERCEPTORS:代表所有已注册的 HttpInterceptor 构成的数组
* APP_BASE_HREF:更改域名后的基础地址前半部分
* */
