import { Environment } from '../models/Environment';

const BGY: Environment = {
  name: 'bgy',
  environment: 'standard',
  protocol: 'https://',
  ip: 'crsapi.bgyhotel.com',
  picture_ip: 'crs.bgyhotel.com/picture',
  port: '',
  pattern: 'api',
  version: '', 
  // gateway
  gateway: {
    spring_cloud: '/',
    log: '/log/',
    cache: '/',
    code: '/code_manage/',
    rate: '/rate/',
    mdm: '/mdm/',
    auth: '/userrole/',
    room_stay: '/inventory/',
    channel: '/channel/',
    order: '/order/',
    profile: '/profile/',
    interface: '/adapter/',
    safe: '/db/',
    crs: '/',
    ota: '/otaadapter/',
    message: '/messagesender/',
    report: '/report/',
  },
};
export default BGY;
