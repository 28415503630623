import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectivePreloadingStrategyService } from './shares/service/pre-loading/selective-preloading-strategy.service';
import { AuthGuard } from './services/auth/auth.guard';
import { ProtectComponent } from './pages/index/protect/protect.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  // 首页保护页面
  {
    path: 'protect',
    component: ProtectComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: 'index',
    loadChildren: () => import('./pages/index/index.module').then(m => m.IndexModule),
    canActivate: [ AuthGuard ],
    data: { preload: true }
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: SelectivePreloadingStrategyService,
    scrollPositionRestoration: 'top',
  }) ],
  exports: [ RouterModule ],
  providers: [ SelectivePreloadingStrategyService ]
})
export class AppRoutingModule {
}
