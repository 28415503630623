import { Environment, GatewayType } from './models/Environment';
import BGY from './mata/bgy';
import BGY2 from './mata/bgy2';
import BGYTest from './mata/bgytest';
import Test2 from './mata/test2';
interface CRSWindow extends Window {
  crs_version: any;
}

declare var window: CRSWindow;
const publish: string = window.crs_version;
let result: Environment | null = null;
switch ( publish ) {
  case 'test2':
    result = Test2;
    break;
  case 'bgy':
    result = BGY;
    break;
  case 'bgy2':
    result = BGY2;
    break;
  case 'bgytest':
    result = BGYTest;
    break;
   
  default:
    result = BGY;
    break;
}

export const env = result;

export class Config {

  private _url: string;

  constructor( gateway_type: GatewayType ) {
    // CRSPRD-701模块合并改动
    const newGateWayType = gateway_type === 'profile' ? 'code' : gateway_type;
    this._url = result && `${result.protocol}${result.ip}${result.port ? `:${result.port}` : ''}${result.gateway[ newGateWayType ]}${result.pattern ? `${result.pattern}` : ''}${result.version ? `/${result.version}` : ''}/`;
  }

  get url(): string {
    return this._url;
  }

  // 可以使用该内容重写url，上面的是默认处理方式
  set url( value: string ) {
    this._url = value;
  }
}
