import {encrypt} from '../../../utils/utils';

export interface User {
    Id: number;
    Level: string;
    LoginName: string;
    Properties: any;
    RoleIds: string[];
    Token: string;
    FunctionIds: string[];
    DefaultHotel: string;
    ChainNo: string;
    AssignTree: {
        ChainNo: string;
        ChainCode: string;
        ChainName: string;
        ChainControl: boolean;
        OnlyChain: boolean;
        IsCROData: boolean;
        BrandInfos: {
            BrandNo: string;
            BrandCode: string;
            BrandName: string;
            PropertyInfos: {
                PropertyNo: string;
                PropertyCode: string;
                PropertyName: string;
                DefaultLanguage: string;
                Active: boolean;
                Currency: string;
                ChildPriceModel: boolean;
            }[];
        }[];
    };
    ErrorCount?: number;
    UsableCount?: number;
    IsLock?: boolean;
    LockTimes?: number;
    ObjectController: {
        CreditCardAuth: boolean;
    };
    Cookie: string;
}

export interface Login {
    UserName: string;
    Password: string;
    VerificationCode: string;
}

export class LoginParam {
    LoginName: string;
    Password: string;
    VerificationCode: string;
    CodeKey: string;
    TimeStamp = (new Date().getTime()).toString();

    constructor(param: Login, guid: string) {
        this.LoginName = param.UserName;
        this.Password = encrypt(param.UserName, param.Password, this.TimeStamp, 'login', );
        this.VerificationCode = param.VerificationCode;
        this.CodeKey = guid;
    }
}

export interface Password {
    LoginName: string;
    OldPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
    DirectReset: boolean;
}

export class PasswordParam {

    UserName: string;
    OldPassword: string;
    NewPassword: string;
    DirectReset: boolean;
    Operator_Id: string;
    TimeStamp = (new Date().getTime()).toString();

    constructor(password: Password, LoginName: string) {
        this.UserName = password.LoginName;
        this.OldPassword = encrypt(password.LoginName, password.OldPassword, this.TimeStamp, 'updatePassword');
        this.NewPassword = encrypt(password.LoginName, password.NewPassword, this.TimeStamp, 'updatePassword');
        this.DirectReset = password.DirectReset;
        this.Operator_Id = LoginName;
    }
}

export interface SelectTree {
    BrandNo: string;
    PropertyNo: string;
    Language: string;
    Currency: string;
    ChildPriceModel: boolean;
}

export interface LoginSSO {
    Token: {
        token: string;
        cipher?: string;
    };
}

export class LoginSSOParam {

    token: string;

    constructor(login: LoginSSO) {
        this.token = login.Token.token;
    }
}
